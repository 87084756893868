import Locale from "@components/core/Locale";
import clx from "classnames";
import { memo } from "react";
const MiniSidebarItem = ({ title, active, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={clx("flex h-12 px-4 w-full items-center cursor-pointer relative rounded-md", {
        "bg-gray-50": active,
        "bg-gray-50 hover:bg-gray-100 ": !active,
      })}
    >
      <span className="font-medium text-sm text-gray-700">
        <Locale code={title} />
      </span>
    </div>
  );
};
export default memo(MiniSidebarItem);
