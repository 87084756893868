import AppButton from "@components/AppButton";
import ENDPOINTS from "@config/endpoints";
import { AuthContext } from "@context/AuthContext";
import axios from "axios";
import { Field, Formik } from "formik";
import { useContext } from "react";
import { useMutation } from "react-query";
import { AppInput, RowInput } from "../../components/Form";

export default function LoginPage() {
  const { login } = useContext(AuthContext);

  // Login Mutation
  const LDM = useMutation((values) => axios.post(ENDPOINTS.auth.login, values), {
    onSuccess: (response) => {
      if (response?.token) login(response?.token);
    },
  });

  return (
    <div className="flex h-screen flex-col justify-center bg-gray-100 items-center">
      <div className="flex flex-col border rounded-sm shadow-md p-8 bg-white w-full lg:w-96">
        <span className="text-center text-xl tracking-wide uppercase text-gray-500 mb-8">Sign In</span>
        <Formik initialValues={{ email: "", password: "" }} onSubmit={LDM.mutate}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div className="flex flex-col">
                <Field component={RowInput} required type="text" name="email" FormElement={AppInput} placeholder="Email" className="mb-2" />
                <Field component={RowInput} FormElement={AppInput} required name="password" type="password" placeholder="Password" className="mb-6" />
                <AppButton mode="primary" title="T.LOGIN" loading={LDM.isLoading} />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
