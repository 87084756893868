import _axios from "@config/axios";
import ENDPOINTS from "@config/endpoints";
import AuthorizeLoading from "@modules/auth/components/AuthorizeLoading";
import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useMutation } from "react-query";

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState(undefined);
  const [loading, setLoading] = useState(true);

  // Make CDN URL
  const makeCDNURL = (mediaID) => `${ENDPOINTS.cdn}/medias/${mediaID}?token=${token}`;

  // Login
  const login = (token) => setToken(token); // Trigger Verification

  // Logout
  const logout = () => {
    setToken(undefined); // Remove Token
    setUser(undefined); // Remove User
    localStorage.removeItem("token"); // Remove Token From LocalStorage
    window.location.reload(); // Reload
    return;
  };

  // Verify User Mutation
  const VUM = useMutation((token) => axios.post(ENDPOINTS.auth.verify, { token }), {
    onSuccess: (response) => {
      localStorage.setItem("token", token); // Persist (new ?) token
      _axios._authorize(token); // Authorize axios
      setUser({ name: "Admin" }); // Set User Information
      setLoading(false);
    },
    onError: () => {
      localStorage.removeItem("token"); // Remove Persisted Token
      setLoading(false);
    },
  });

  // Initial Check
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) setToken(token); // This will trigger VUM
    else setLoading(false);
  }, []);

  // Token Check
  useEffect(() => {
    if (!token) return;
    VUM.mutate(token); // Verify Token
  }, [token]);

  return <Provider value={{ isLogedIn: Boolean(user), token, login, user, logout, makeCDNURL }}>{loading ? <AuthorizeLoading /> : children}</Provider>;
};

export { AuthContext, AuthProvider };
