import toast from "@helpers/toast";
import axios from "axios";

const _initiate = () => {
  const locale = localStorage.getItem("locale");
  axios.defaults.headers.Accept = "application/json";
  axios.defaults.headers["App-Locale"] = locale;
  axios.interceptors.response.use(
    (response) => {
      if (response.headers["content-type"] !== "application/json; charset=utf-8") return response;
      if (response.data.status !== 200) {
        const message = response.data.error?.message;
        if (Array.isArray(message)) message?.map((msg) => toast.warning(msg));
        else toast.warning(message);
        throw new Error();
      }
      return response.data.data;
    },
    (error) => {
      console.log(error);
      throw "API Communication Error";
    }
  );
};

const _authorize = (token) => {
  axios.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (err) => Promise.reject(err)
  );
};

export default { _initiate, _authorize };
