import { Link } from "react-router-dom";
import MiniSidebarItem from "./components/MiniSidebarItem";

export default function MiniSidebar() {
  // Sidebar Items
  const _SIDEBAR = [
    { title: "T.COMPANIES", path: "/companies" },
    { title: "T.USERS", path: "/users" },
    { title: "T.INVOICES", path: "/invoices" },
    { title: "T.TRANSACTIONS", path: "/transactions" },
    { title: "T.INTEGRATIONS", path: "/integrations" },
    { title: "T.PERMISSION_GROUPS", path: "/permission-groups" },
    { title: "T.NUMBERS", path: "/numbers" },
    { title: "T.TEMPLATES", path: "/templates" },
    { title: "T.VERSIONS", path: "/versions" },
  ];

  return (
    <div className="w-48 hidden md:flex flex-shrink-0 border-r flex-col space-y-2 p-2 bg-white">
      {_SIDEBAR.map(({ title, icon, active, path, onClick }, _index) => (
        <Link key={path} to={path}>
          <MiniSidebarItem key={_index} title={title} icon={icon} active={active} />
        </Link>
      ))}
    </div>
  );
}
