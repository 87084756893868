import { Link } from "react-router-dom";
import MobileTabBarItem from "./components/BottomTabItem";

export default function BottomTabs() {
  // Menu
  const _MENU = [
    { title: "T.DASHBOARD", path: "/dashboard", icon: "fa-dashboard" },
    { title: "T.LEADS", path: "/leads", icon: "fa-users" },
    { title: "T.MESSENGER", path: "/messenger", icon: "fa-sms" },
    { title: "T.CALLCENTER", service: "call", icon: "fa-phone" },
    { title: "T.NOTIFICATIONS", icon: "fa-bell" },
  ];

  return (
    <div className={`md:hidden flex-shrink-0 grid grid-cols-5 w-full shadow-inner fixed  bottom-0 bg-white border-t h-16 z-50 mt-10`}>
      {_MENU.map(({ icon, active, path, notifications, onClick }, _index) => (
        <>
          {path ? (
            <Link to={path}>
              <MobileTabBarItem key={_index} icon={icon} active={active} notifications={notifications} />
            </Link>
          ) : (
            <MobileTabBarItem key={_index} icon={icon} active={active} notifications={notifications} onClick={onClick} />
          )}
        </>
      ))}
    </div>
  );
}
