import { Switch } from "@headlessui/react";
import clx from "classnames";
export default function AppSwitch({ value, onChange, ...props }) {
  return (
    <div className="form border-none flex items-center justify-end">
      <Switch
        checked={value}
        onChange={onChange}
        className={clx(
          value ? "bg-blue-500" : "bg-gray-200",
          "inline-flex items-center h-6 w-12 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out "
        )}
        {...props}
      >
        <span
          aria-hidden="true"
          className={clx(
            value ? "translate-x-6" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </Switch>
    </div>
  );
}
