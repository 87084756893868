import RouteContainer from "@components/core/RouteContainer";
import MasterLayout from "@components/layouts/MasterLayout";
import { lazy } from "react";
import { Navigate } from "react-router-dom";

const CompanyPage = lazy(() => import("@modules/companies/CompanyPage"));
const PlansPage = lazy(() => import("@modules/plans/PlansPage"));
const NumbersPage = lazy(() => import("@modules/numbers/NumbersPage"));
const UsersPage = lazy(() => import("@modules/users/UsersPage"));
const PGPage = lazy(() => import("@modules/permission-groups/PGPage"));
const InvoicePage = lazy(() => import("@modules/invoices/InvoicePage"));
const IntegrationPage = lazy(() => import("@modules/integrations/IntegrationPage"));
const TransactionPage = lazy(() => import("@modules/transactions/TransactionPage"));
const TemplatePage = lazy(() => import("@modules/templates/TemplatePage"));
const VersionPage = lazy(() => import("@modules/versions/VersionPage"));

const paths = [
  { path: "/auth/*", element: <Navigate to="/companies" /> },
  { path: "numbers/*", element: <NumbersPage /> },
  { path: "companies/*", element: <CompanyPage /> },
  { path: "invoices/*", element: <InvoicePage /> },
  { path: "transactions/*", element: <TransactionPage /> },
  { path: "plans/*", element: <PlansPage /> },
  { path: "users/*", element: <UsersPage /> },
  { path: "integrations/*", element: <IntegrationPage /> },
  { path: "permission-groups/*", element: <PGPage /> },
  { path: "templates/*", element: <TemplatePage /> },
  { path: "versions/*", element: <VersionPage /> },
];

export default function PrivateRoutes() {
  return <RouteContainer paths={paths} layout={<MasterLayout />} />;
}
