import { Dialog } from "@headlessui/react";
import clx from "classnames";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { AppLayoutContext } from "src/context/AppLayoutContext";
import CloseButton from "../components/CloseButton";

const WIDTHS = {
  md: "w-2/3 md:w-1/3",
  lg: "w-full md:w-1/2",
  full: "w-full",
};

export default function AppModal({ show = false, size = "md", scrollable = false, onHide = () => {}, header, body, footer, bodyClassName = "" }) {
  const intl = useIntl();
  const { isMobile } = useContext(AppLayoutContext);
  return (
    <Dialog as="div" open={show} className="relative z-10" onClose={onHide}>
      {/* Backdrop */}
      <div className="fixed inset-0 bg-gray-700 bg-opacity-20 transition-opacity" />
      <div className="fixed inset-0 z-10 flex justify-center px-0 pb-16 md:py-16 md:items-center">
        <Dialog.Panel
          className={`relative ${
            isMobile ? "w-full" : WIDTHS[size]
          } max-h-full flex flex-col transform transition-all  bg-white rounded-sm shadow-none md:shadow-sm`}
        >
          <div className="flex items-center justify-between h-16 flex-shrink-0 px-4 border-b">
            <Dialog.Title as="div" className="card-title flex-shrink-0">
              {typeof header === "string" ? intl.formatMessage({ id: header ?? "-" }) : header}
            </Dialog.Title>
            <CloseButton onClick={onHide} />
          </div>
          <div className={clx("flex-grow", { "p-4": !bodyClassName }, { "overflow-y-auto": isMobile ? true : scrollable })}>{body}</div>
          {footer && <div className="p-4 flex items-center border-t">{footer}</div>}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
