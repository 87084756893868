import { Outlet } from "react-router-dom";
import BottomTabs from "./bottom-tabs/BottomTabs";
import MiniSidebar from "./mini-sidebar/MiniSidebar";

export default function MasterLayout() {
  return (
    <div className="h-screen flex flex-col md:flex-row overflow-hidden">
      <MiniSidebar />
      <div className="h-screen pb-32 md:pb-0 flex-1 bg-[#F8F9FA] overflow-y-auto">
        <Outlet />
      </div>
      {/* <NotificationStack /> */}
      {/* Mobile Bottom Tabbar */}
      <BottomTabs />
    </div>
  );
}
