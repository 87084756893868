import { Outlet, Route, Routes } from "react-router-dom";
import SuspensedView from "./SuspensedView";

export default function RouteContainer({ paths, layout = <Outlet /> }) {
  return (
    <Routes>
      <Route element={layout}>
        {paths.map(({ code, service, path, element }, _index) => (
          <Route key={_index} path={path} element={<SuspensedView>{element}</SuspensedView>} />
        ))}
      </Route>
    </Routes>
  );
}
